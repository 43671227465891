import request from '../utils/request'
import qs from 'qs'
import {encrypt} from "../utils/rsautil" //RSA加密类
import md5 from 'js-md5';

/**
 * 获取验证码
 * @param phone 手机
 * @param code 验证码
 * @returns {AxiosPromise}
 */
export function getCode(phone, code){
    return request({
        url: 'auth/getCode.do',
        method: 'post',
        data: qs.stringify({phone: encrypt(phone),code: encrypt(code)})
    })
}

/**
 * 用户名和密码登录
 * @param userName
 * @param pass
 * @param code
 * @returns {*}
 */
export function nameLogin(userName, pass, code){
    return request({
        url: 'auth/login.do',
        method: 'post',
        data: qs.stringify({userName: encrypt(userName),pass:encrypt(md5(pass)),code: encrypt(code)})
    })
}
/**
 * 短信验证码登录
 * @param userName
 * @param pass
 * @param code
 * @returns {*}
 */
export function phoneLogin(phone, code){
    return request({
        url: 'auth/phoneLogin.do',
        method: 'post',
        data: qs.stringify({phone: encrypt(phone),code: encrypt(code)})
    })
}

/**
 * 获取政务服务登录地址
 */
export function getZwfwLoginUrl(){
    return request({
        url: 'auth/getZwfwLoginUrl.do',
        method: 'post'
    })
}

/**
 * 获取政务服务登录地址
 */
export function checkLogin(platform){
    return request({
        url: 'auth/checkLogin.do',
        method: 'post',
        data: qs.stringify({platform: platform})
    })
}