<template>
  <van-field
      v-model="phoneCode"
      type="number"
      center
      clearable
      label="短信验证码"
      left-icon="lock"
      placeholder="短信验证码"
      @blur="smsCodeSate"
      maxlength="6"
      required
      :rules="[{ required: true, message: '' }]"
  >
    <template #button>
      <van-button v-if="second == 0" size="small" type="primary" native-type="button" @click="sendSmsCode">发送验证码</van-button>
      <van-button v-if="second > 0" disabled size="small" type="primary" native-type="button">{{ phoneCodeMsg }}</van-button>
    </template>
  </van-field>
</template>

<script>
import {getCode} from "../api/auth";

export default {
  name: "SmsCode",
  props:{
    phoneNumber:{},
    verifyCode:{},
    isVerifyCode:{
      type:Boolean
    }
  },
  data () {
    return {
      second:0,
      phoneCode:'',
      phoneCodeMsg:'等待60秒'
    }
  },
  methods:{
    /**
     * 失去焦点触发
     * @constructor
     */
    smsCodeSate(){
      this.$emit('smsCodeSate',this.phoneCode)
    },
    sendSmsCode(){
      const that = this
      if (that.second > 0) return;
      if (that.phoneNumber == ''){
        that.$toast('请输入手机号码')
        return
      }
      if (that.isVerifyCode && that.verifyCode == ''){
        that.$toast('请输入验证码')
        return
      }
      getCode(that.phoneNumber, that.verifyCode).then(response => {
        if (response.code == process.env.VUE_APP_SUCCESS_CODE){
          that.$toast('发送短信验证码成功')
          that.second = 60
          const timer = setInterval(() => {
            that.second--;
            if (that.second > 0) {
              that.phoneCodeMsg = '等待 '+ this.second +' 秒'
              // toast.message = `倒计时 ${second} 秒`;
            } else {
              that.second = 0
              clearInterval(timer);
              // 手动清除
            }
          }, 1000);
        }else{
          that.$toast(response.message)
        }
      }).catch(() => {
        that.$toast('服务器错误，请稍后再试！')
      })
    }
  }
}
</script>

<style scoped>

</style>