<template>
  <div>
    <van-uploader v-model="attFiles" multiple :max-count="maxCount"
                  :max-size="uploadSize"
                  @oversize="onOversize"
                  :after-read="afterRead"
                  :before-delete="afterDelete"
    />
    <div> {{attFiles.length}} / {{maxCount}}</div>
  </div>
</template>
<script>
//import axios from '../plugins/axios'

export default {
  name: "UploadPic",
  props:{
    uploadSize:{},
    maxCount:{},
    tag:{}
  },
  data () {
    return{
      attFiles: [],
      uploadDates:[]
    }
  },
  methods:{
    onOversize(){
      this.$toast('图片大小不能超过 '+ this.uploadSize/1024+'KB')
    },
    /**
     * 选择文件回调
     * @param file
     */
    afterRead(file){
      const that = this
      file.status = 'uploading'
      file.message = '上传中...'
      var forms = new FormData()
      var configs = {
        headers:{'Content-Type':'multipart/form-data'}
      };
      forms.append('file',file.file)
      this.$axios.post(process.env.VUE_APP_UPLOAD_URL,forms ,configs).then(res=>{
        if (res.code == process.env.VUE_APP_SUCCESS_CODE){
          that.uploadDates.push(res.data)
          that.$emit('uploadSate',{tag:that.tag,uploadDates:that.uploadDates})
          file.status = 'done'
          file.message = '上传成功'
        }else{
          file.status = 'failed'
          file.message = '上传失败'
        }
      }).catch(() => {
        file.status = 'failed'
        file.message = '上传失败'
      })
      //console.log(file)
      /*file.message = '上传中...'
      file.status = 'uploading'
      let fileName=file.file.name;
      let index1=fileName.lastIndexOf(".")
      let index2=fileName.length
      let suffix=fileName.substring(index1,index2)
      upload(file.content,suffix).then(res=>{
        //console.log(res)
        if (res.code == process.env.VUE_APP_SUCCESS_CODE){
          that.uploadDates.push(res.data)
          that.$emit('uploadSate',{tag:that.tag,uploadDates:that.uploadDates})
          file.status = 'done'
          file.message = '上传成功'
        }else{
          file.status = 'failed'
          file.message = '上传失败'
        }
      }).catch(() => {
        file.status = 'failed'
        file.message = '上传失败'
      })
      this.$axios.post(process.env.VUE_APP_UPLOAD_URL,forms ,configs).then(res=>{
        console.log(res)
        if (res.errno == process.env.VUE_APP_SUCCESS_CODE){
          that.push(res.data)
          that.$emit('uploadSate',{tag:this.tag,data:res.data})
          //that.dataFrom.picUrl = res.data.url
          file.status = 'done'
          file.message = '上传成功'
        }else{
          file.status = 'failed'
          file.message = '上传失败'
        }
      })*/
    },
    afterDelete(file,detail){
      const that = this
      const index = detail.index
      //console.log(file)
      //console.log(detail)
      if (that.attFiles.length > index){
        //const data = that.uploadDates[index]
        that.attFiles.splice(index,1)
        that.uploadDates.splice(index,1)
        that.$emit('uploadSate',{tag:that.tag,uploadDates:that.uploadDates})
      }
    },
    /**
     * 上传文件
     * @param type 1 身份证件 2 不动产 3 授权委托、经办人有效身份证件
     */
    uploadFile(file){
      console.log(file)
    }
  }
}
</script>

<style scoped>

</style>