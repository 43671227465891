import request from '../utils/request'
//import qs from 'qs'
export function getAreaList(){
    return request({
        url: 'bzAppointment/getAreaList.do',
        method: 'post',
        //data: qs.stringify({accountNo: accountNo,code:code})
    })
}
/**
 * 提交用水报装信息
 * @param param
 * @returns {*}
 */
export function submitData(param){
    param['postType'] = 'application/json'
    return request({
        url: 'bzAppointment/submit.do',
        method: 'post',
        data: param
    })
}
