<template>
  <div>
    <div class="container">
      <div class="header-container">
        <header>
          <van-nav-bar
              :title="title"
              left-text="返回"
              right-text=""
              left-arrow
              @click-left="onBack"
          />
        </header>
      </div>
      <van-form @submit="onSubmit">
        <div class="section-box" style="padding: 5px">
          <div style="margin:8px;padding:4px;background:#eee">
            <span style="display:block;width:100%;color: #1989fa; font-size: 16px;line-height:30px;">办理说明:</span>
            <span style="font-size:14px;line-height:24px;color:red">本业务仅办理工商客户新装、改移装、增容服务、临时施工用水的自来水接入工程和居民用水新装、改移装自来水接入工程。</span>
          </div>
        <van-field
            v-model="appointFrom.ownerName"
            name="informer"
            label="业主名称"
            placeholder="请填写业主名称"
            border
            colon
            required
            clearable
            :rules="[{ required: true, message: '' }]"
        />
          <van-field
              readonly
              clickable
              name="ownerAreaName"
              :value="appointFrom.ownerAreaName"
              label="所属区域"
              colon
              required
              placeholder="请选择所属区域"
              @click="showOwnerAreaPicker = true"
          />
          <van-popup v-model="showOwnerAreaPicker" position="bottom">
            <van-picker
                show-toolbar
                :columns="ownerAreaNameSet"
                @confirm="onAreaConfirm"
                @cancel="showOwnerAreaPicker = false"
            />
          </van-popup>

          <van-field
              readonly
              clickable
              name="categoryName"
              :value="appointFrom.categoryName"
              label="预约类别（报装/改移装）"
              colon
              required
              placeholder="请选择预约类别"
              @click="showCategoryPicker = true"
          />
          <van-popup v-model="showCategoryPicker" position="bottom">
            <van-picker
                show-toolbar
                :columns="categoryNameSet"
                @confirm="onCategoryConfirm"
                @cancel="showCategoryPicker = false"
            />
          </van-popup>

        <van-field
              readonly
              clickable
              name="ownerType"
              :value="appointFrom.ownerType"
              label="客户类别"
              colon
              required
              placeholder="请选择客户类别"
              @click="showOwnerTypePicker = true"
          />
          <van-popup v-model="showOwnerTypePicker" position="bottom">
            <van-picker
                show-toolbar
                :columns="ownerTypeNameSet"
                @confirm="onOwnerTypeConfirm"
                @cancel="showOwnerTypePicker = false"
            />
          </van-popup>

          <van-field
              readonly
              clickable
              name="waterType"
              :value="appointFrom.waterType"
              label="用水类别"
              colon
              required
              placeholder="请选择用水类别"
              @click="showWaterTypePicker = true"
          />
          <van-popup v-model="showWaterTypePicker" position="bottom">
            <van-picker
                show-toolbar
                :columns="waterTypeNameSet"
                @confirm="onWaterTypeConfirm"
                @cancel="showWaterTypePicker = false"
            />
          </van-popup>

          <van-field v-if="showWaterOld"
              v-model="appointFrom.waterOldNumber"
              name="waterOldNumber"
              label="原用水户号"
              placeholder="请填写原用水户号"
              border
              colon
              required
              clearable
              :rules="[{ required: true, message: '' }]"
              error-message="请输入原用水户号查询欠费情况,如存在欠费，则需先结清欠费才能进行预约!"
          />

          <van-field v-if="showWaterOld"
              v-model="appointFrom.waterOldName"
              name="waterOldName"
              label="原用水户名"
              placeholder="原用水户名"
              readonly
              border
              colon
              required
              clearable
              :rules="[{ required: true, message: '' }]"
          />


          <van-field
              v-model="appointFrom.waterDemand"
              name="waterDemand"
              label="用水需求描述"
              placeholder="请填写用水需求"
              border
              colon
              rows="5"
              autosize
              type="textarea"
              :rules="[{ required: true, message: '' }]"
          />
          <van-field
              v-model="appointFrom.waterAddress"
              name="waterAddress"
              label="用水地址"
              placeholder="请填写用水地址"
              border
              colon
              rows="2"
              autosize
              type="textarea"
              required
              :rules="[{ required: true, message: '' }]"
          />
          <van-field
              v-model="appointFrom.agentName"
              name="agentName"
              label="经办人"
              placeholder="请填写经办人姓名"
              border
              required
              colon
              clearable
          />
          <van-field
              v-model="appointFrom.agentPhone"
              name="agentPhone"
              label="经办人电话"
              type="number"
              placeholder="请填写经办人电话"
              border
              colon
              required
              clearable
              :rules="[{ required: true, message: '' }]"
          />

        </div>
        <div v-if="appointFrom.ownerDocDescribeHtml.length>0" style="margin:8px;padding:4px;background:#eee">
          <span style="display:block;width:100%;color: #1989fa; font-size: 16px;line-height:30px;">温馨提示:</span>
          <span style="font-size:14px;line-height:24px;color:red">请您提前准备好办理材料，我司工作人员将在现场踏勘时核实并采集，无法采集信息或将影响办理，办理材料如下：</span>
          <ul>
             <li v-for="(info,index) in appointFrom.ownerDocDescribeHtml" style="font-size:14px;line-height:30px;">
                 {{(index+1)+"、"+info}}
             </li>
           </ul>
        </div>
        <SmsCode :phone-number="appointFrom.agentPhone" verify-code="0" @smsCodeSate="smsCodeSate"></SmsCode>
        <div style="margin: 16px;">
          <van-button type="info" round block>提交</van-button>
        </div>
      </van-form>
    </div>
    <Loading :loding-txt="lodingTxt" :is-show="isShow"/>
  </div>
</template>

<script>
import Loading from "../../components/logding";
import {getAreaList, submitData} from "../../api/bzAppointment";
import UploadPic from "../../components/UploadPic";
import SmsCode from "../../components/SmsCode";
import {Dialog} from "vant";
export default {
  name: "bzAppointmentIndex",
  components: {Loading, UploadPic,SmsCode},
  data(){
    return{
      isShow : false,
      title:"用水报装预约",
      bzType: this.$route.query.bzType,
      lodingTxt: '加载中...',
      noticeScroll:false,
      showPicker: false,
      showWaterOld:false,
      showOwnerAreaPicker:false,
      showCategoryPicker:false,
      showOwnerTypePicker:false,
      showWaterTypePicker:false,
      areas:[],
      categoryObj:{'新装':1,'改移装':2,'增容':3},
      categoryNameSet:['新装','改移装','增容'],
      ownerTypeNameSet:['居民客户','工商、行政、事业单位等非居民客户'],
      waterTypeNameSet:['居民客户新装','工商客户新装','临时施工用水','居民客户改移装','工商客户改移装'],
      waterTypedocSet:{'居民客户新装':'doc2','工商客户新装':'doc1','临时施工用水':'doc3','居民客户改移装':'doc2','工商客户改移装':'doc1'},
      docNoticeTemp:{
        'doc1':['营业执照原件或复印件加盖公章（三证合一）。'],
        'doc2':['客户身份证原件或复印件；非本人办理，需提供授权委托书及被委托人身份证原件；','产权证或复印件（产权证、房产证、购房合同等任意一项）。'],
        'doc3':['营业执照原件或复印件加盖公章（三证合一）；','用水地块的土地归属相关文件。']
      },
      ownerAreaNameSet:[],
      areaNmaes:[],
      appointFrom:{
        category:'',
        categoryName:'',
        ownerName:'',
        ownerPhone:'',
        ownerArea:'',
        ownerAreaName:'',
        ownerType:'',
        ownerDocDescribe:'test',
        ownerDocDescribeHtml:[],
        waterType:'',
        waterAddress:'',
        waterOldNumber:'',
        waterOldName:'',
        waterOldAddress:'',
        waterDemand:'',
        agentName:'',
        agentPhone:'',
        vercode:'',
      }
    }
  },
  created() {
    document.title = this.title;
    this.getAreaList();
  },
  methods:{
    /**
     * 提交
     */
    onSubmit(){
      const that = this
      console.log(that.appointFrom)
      if (!that.appointFrom.ownerArea || that.appointFrom.ownerArea==''){
        this.$toast('请选择所属区域')
        return
      }
      if (!that.appointFrom.category || that.appointFrom.category==''){
        this.$toast('请选择预约类别')
        return
      }
      if (!that.appointFrom.ownerType || that.appointFrom.ownerType==''){
        this.$toast('请选择客户类别')
        return
      }
      if (!that.appointFrom.waterType || that.appointFrom.waterType==''){
        this.$toast('请选择用水类别')
        return
      }
      that.lodingTxt = '正在保存...'
      that.isShow = true
      submitData(that.appointFrom).then(response => {
        that.isShow = false
        if (response.code == process.env.VUE_APP_SUCCESS_CODE) {
          Dialog.alert({
            title: '温馨提示',
            message: response.message,
          }).then(() => {
            window.location.reload()
          });
        }else{
          that.$toast(response.message)
        }
      }).catch(() => {
        that.isShow = false
        that.$toast('服务器错误，请稍后再试！')
      })

    },
    onBack : function (){
      this.$router.go(-1);
    },
    getAreaList(){
      const that = this
      that.isShow = true
      that.lodingTxt = '查询中...'
      getAreaList().then(response => {
        console.log(response)
        that.isShow = false
        if (response.code == process.env.VUE_APP_SUCCESS_CODE) {
          that.areas = response.data
          that.areaNmaes = [];
          that.ownerAreaNameSet=[];
          that.areas.forEach(item =>{
            that.areaNmaes.push(item.reportArea)
            that.ownerAreaNameSet.push(item.reportArea);
          })
        }else{
          that.$toast(response.message)
        }
      }).catch(() => {
        that.code = ''
        that.isShow = false
        that.$toast('服务器错误，请稍后再试！')
      })
    },
    onAreaConfirm(e) {
      console.log(e)
      this.showOwnerAreaPicker = false
      //获取id
      let i = 0
      this.ownerAreaNameSet.forEach(item => {
        if (item == e){
          const data = this.areas[i]
          console.log(data)
          this.appointFrom.ownerAreaName = item
          this.appointFrom.ownerArea = data.id
          return
        }
        i++
      })
    },

    onCategoryConfirm(e){
      this.showCategoryPicker = false;
      this.appointFrom.category = this.categoryObj[e];
      this.appointFrom.categoryName=e;
      if(e=='新装'){
        this.showWaterOld=false;
      }else{
        this.showWaterOld=true;
      }
    },

    onOwnerTypeConfirm(e){
      this.showOwnerTypePicker = false;
      this.appointFrom.ownerType = e;
    },

    onWaterTypeConfirm(e){
      this.showWaterTypePicker = false;
      this.appointFrom.waterType = e;
      if(e!=""){
        let i = 1;
        let describe="";
        this.docNoticeTemp[this.waterTypedocSet[e]].forEach(item => {
          describe=describe+i+"、"+item
          i++
        })
        this.appointFrom.ownerDocDescribe= describe;
        this.appointFrom.ownerDocDescribeHtml=this.docNoticeTemp[this.waterTypedocSet[e]];
      }else{
        this.appointFrom.ownerDocDescribe="";
        this.appointFrom.ownerDocDescribeHtml=[];
      }

    },

    smsCodeSate(code){
      console.log('获取短信验证码:'+code)
      this.appointFrom.vercode = code
    },
  }
}
</script>

<style scoped>

</style>
